.product-image-container {
  overflow: hidden;
}

.product-image {
  transition: transform 0.3s ease;
}

.product-image:hover {
  transform: scale(1.1);
}

.product-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.product-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(151, 241, 5, 0.2);
}

.btn-primary {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-primary:hover {
  background-color: #0056b3;
  border-color: #004085;
}

.ml-2 {
  margin-left: 0.5rem;
}
banner-container .carousel-item img {
    width: 100%;
    height: 150px; /* Atur tinggi sesuai kebutuhan */
    object-fit: cover; /* Pastikan gambar diisi dengan baik ke dalam area yang ditentukan */
  }
  
  .banner-container .carousel-caption {
    background: rgba(0, 0, 0, 0.5); /* Menambahkan latar belakang semi-transparent untuk caption */
    color: #fff; /* Warna teks caption */
    text-align: center; /* Posisi teks ke tengah */
  }
  
  .banner-container .carousel-caption h3 {
    font-size: 1.5rem; /* Ukuran font judul */
  }
  
  .banner-container .carousel-caption p {
    font-size: 1rem; /* Ukuran font teks */
  }